import "./App.css";
import Pages from "./Pages";
import 'bootstrap/dist/css/bootstrap.min.css';
import Test from "./test";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TermAndCondition from './TermAndCondition';
import PrivacyPolicy from './PrivacyPolicy';
import ReturnPolicy from './ReturnPolicy';
import Disclaimer from './Disclaimer';
import FAQ from './FAQ';


function App() {
  return (
    <div>
      {/* <Test/> */}
      
      <Router>
      <div>
        <Routes>
          <Route path="/" element={<Pages/>}/>
          <Route path="/terms" element={<TermAndCondition />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/return" element={<ReturnPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/FAQ" element={<FAQ />} />
        </Routes>
       
        
      </div>
    </Router>
    {/* <Pages /> */}
      
    </div>
  );
}

export default App;
