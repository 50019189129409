import React from 'react';
import Header from "./Header";
import "./PrivacyPolicy.css"
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
const ReturnPolicy = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate('/'); // Navigates to the Home page
    };
    return (
        <>
        
   {/* <button onClick={handleBackClick} className="back-button">
      <IoArrowBackCircle />
    </button> */}
       
        <div data-elementor-type="wp-page" data-elementor-id="1415" class="elementor elementor-1415">
            <section class="elementor-section elementor-top-section elementor-element elementor-element-140e126 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="140e126" data-element_type="section">
                <div class="elementor-container elementor-column-gap-no"><div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b219e34" data-id="b219e34" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated"><div class="elementor-element elementor-element-ee548c9 elementor-align-left elementor-widget elementor-widget-bdevs-title-widget" data-id="ee548c9" data-element_type="widget" data-widget_type="bdevs-title-widget.default">
                        <div class="elementor-widget-container"><div class="page-title-area pb-100 bg-light bg-img" data-bg-image="https://shtheme.com/demosd/teeno/wp-content/uploads/2023/11/page-title-bg-1.jpg" >
                            <div class="container-fluid">
                                <div class="content text-center" style={{marginTop:"30px"}}>
                                <button onClick={handleBackClick} className="back-button">
      <IoArrowBackCircle />
    </button>
                                    <h2>Payment Policy</h2><nav aria-label="breadcrumb">
                                        <ol class="breadcrumb"><li class="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                            <li class="breadcrumb-item active" aria-current="page">Payment Policy</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            </div>
                        </div></div>
                    </div>
                </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-9972e87 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="9972e87" data-element_type="section">
                <div class="elementor-container elementor-column-gap-no">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e43413b" data-id="e43413b" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-cb61db6 elementor-align-left elementor-widget elementor-widget-bdevs-terms-widget" data-id="cb61db6" data-element_type="widget" data-widget_type="bdevs-terms-widget.default">
                                <div class="elementor-widget-container">
                                    <section class="terms-condition-area pt-100 pb-70">
                                        <div class="container-fluid"><div class="row">
                                            <div class="col-lg-9 col-md-12 m-auto">
                                                <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                    <h4>Payment Policy</h4>
                                                    <p>Use of the Website, including registering for an account is free. Ordering any product from the Website is subject to the payment made to Dhokla House. Currently, payments for the Orders placed are made through online payment gateways like Cards, Wallet, UPI, QRCode, Netbanking. Dhokla House may revise prices or methods of payment at any time without notice. Please be aware that your use of the website may require and utilize an internet connection or data access. To the extent that third-party service provider or carrier charges for your internet or data usage are websites, you agree to be solely responsible for those charges.</p><p>
                                                        We reserve the right to cancel any delivery of goods ordered if full payment is not received. We reserve the right to cancel any transaction that we have reason to believe to have been fraudulently made, including by unauthorized use of a credit card, debit card, or other payment methods.
                                                        You are solely responsible for any taxes on amounts you may pay or obtain through the Website. Dhokla House will deduct applicable charges and taxes from any payable amounts, as required by law.</p></div>
                                            </div>
                                        </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </>
    );
};
export default ReturnPolicy;