import React from 'react';
import "./PrivacyPolicy.css"
import Header from "./Header";
import { useNavigate } from 'react-router-dom';
import { IoArrowBackCircle } from "react-icons/io5";
const FAQ = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate('/'); // Navigates to the Home page
    };
    return (
    <>

   {/* <button onClick={handleBackClick} className="back-button">
      <IoArrowBackCircle />
    </button>
    */}
    <section class="elementor-section elementor-top-section elementor-element elementor-element-c62d4c6 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="c62d4c6" data-element_type="section">
        <div class="elementor-container elementor-column-gap-no">
            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5ad36f4" data-id="5ad36f4" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                    <div class="elementor-element elementor-element-5dca528 elementor-align-left elementor-widget elementor-widget-bdevs-faq-widget" data-id="5dca528" data-element_type="widget" id="contact" data-widget_type="bdevs-faq-widget.default">
                        <div class="elementor-widget-container">
                            <div class="faq-area pt-100 pb-60">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12" style={{display:"flex",justifyContent:"center",marginTop:"25px"}}>
                                            <div class="section-title title-center mb-50 aos-init aos-animate" data-aos="fade-up">
                                            <button onClick={handleBackClick} className="back-button">
      <IoArrowBackCircle />
    </button>
                                                <span class="subtitle color-primary">FAQ's</span>
                                                <h2 class="title mt-0 mw-100">Frequently Asked Questions</h2>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div style={{display:"flex",justifyContent:"center"}}>
                                                <div class="col-lg-9
                                                 aos-init aos-animate" data-aos="fade-up">
                                                    <div class="accordion mb-10" id="faqAccordion">
                                                        <div class="accordion-item mb-30"><h6 class="accordion-header" id="headingOne">
                                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                1. What is MyDukaan App?                                    </button>
                                                        </h6>
                                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faqAccordion" >
                                                                <div class="accordion-body"><p>
                                                                    Introducing MyDukaan, the ultimate cross-selling solution tailored for small businesses in the e-commerce world!With our mobile app, small businesses can now effortlessly boost sales by offering personalized cross-sell suggestions to their customers.Say hello to increased revenue and enhanced customer satisfaction as shoppers discover complementary products seamlessly. Join us as we revolutionize the e-commerce landscape for small businesses, one cross-sell at a time!</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="accordion-item mb-30">
                                                            <h6 class="accordion-header" id="headingTwo">
                                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">

2. How to Subscribe this App                                    </button></h6><div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion"><div class="accordion-body"><p>Launch the MyDukaan website, go to the Plans section, choose the relevant plan, and click on the Subscribe button. This will open the registration page, where you'll need to fill in your information and confirm your email address. After successfully validating your email, the payment page will open, allowing you to pay the amount and complete the registration process successfully.</p></div></div></div><div class="accordion-item mb-30"><h6 class="accordion-header" id="headingThree">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
3. How do Users Download My App?                                    </button></h6><div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion"><div class="accordion-body"><p>Users can download your app, on popular platforms such as the Apple App Store for iOS devices and the Google Play Store for Android devices.
We will upload your app on Play Store and App Store without any extra amount.</p></div></div></div><div class="accordion-item mb-30"><h6 class="accordion-header" id="headingFour">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
4. How do I Upload Products on the Admin Side?                                    </button></h6><div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#faqAccordion"><div class="accordion-body"><p>After successful payment, you will receive the admin credentials to your registered email. You can log in to the admin panel and upload the product and other branding information. If you need any help, you can connect with us at any time using the provided contact number.</p></div></div></div><div class="accordion-item mb-30"><h6 class="accordion-header" id="headingFive">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
5. Can I make an Appointment?                  </button>
</h6>
<div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#faqAccordion">
<div class="accordion-body">
<p>You can make an appointment, and we can schedule a demo if you'd like. This way, you'll get a clear idea about the product. After a successful demo presentation, you can subscribe on our website</p>
</div></div>
</div></div></div>
                                                                                                        

</div></div></div></div></div></div></div></div></div></div></section> 
</>
)};
export default FAQ;
