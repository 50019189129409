import React from 'react';
import "./PrivacyPolicy.css"
import Header from "./Header";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
const PrivacyPolicy = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate('/'); // Navigates to the Home page
    };
    return (
        <>
       
   {/* <button onClick={handleBackClick} className="back-button">
      <IoArrowBackCircle />
    </button> */}
        <div data-elementor-type="wp-page" data-elementor-id="3" class="elementor elementor-3">
            <section class="elementor-section elementor-top-section elementor-element elementor-element-2cd255e elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="2cd255e" data-element_type="section">
                <div class="elementor-container elementor-column-gap-no">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4caad8a" data-id="4caad8a" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-3b3e56b elementor-align-left elementor-widget elementor-widget-bdevs-title-widget" data-id="3b3e56b" data-element_type="widget" data-widget_type="bdevs-title-widget.default">
                                <div class="elementor-widget-container">
                                    <div class="page-title-area">
                                        <div class="container-fluid">
                                       
                                            <div class="content text-center" style={{marginTop:"30px"}}>
                                            <button onClick={handleBackClick} className="back-button">
      <IoArrowBackCircle />
    </button>
                                                <h2>Privacy Policy</h2>
                                                <nav aria-label="breadcrumb">

                                                    <ol class="breadcrumb">
                                                        <li class="breadcrumb-item">
                                                            <a href="/">Home</a>
                                                        </li>
                                                        <li class="breadcrumb-item active" aria-current="page">
                                                            Privacy Policy
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>
                                        </div>
                                    
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="elementor-section elementor-top-section elementor-element elementor-element-ed4d595 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="ed4d595" data-element_type="section">
                <div class="elementor-container elementor-column-gap-no">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-98b62a5" data-id="98b62a5" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-d96dc55 elementor-align-left elementor-widget elementor-widget-bdevs-terms-widget" data-id="d96dc55" data-element_type="widget" data-widget_type="bdevs-terms-widget.default">
                                <div class="elementor-widget-container">
                                    <section class="terms-condition-area pb-70">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-lg-9 col-md-12 m-auto">
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4>1. Introduction</h4>
                                                        <p><span lang="EN-US">Welcome to <b>MyDukaanApp</b>.</span></p>
                                                        <p><b><span lang="EN-US">MyDukaanApp</span></b>
                                                            <span lang="EN-US"> operates <a href="https://mydukaanapp.com/">
                                                                <b>https://mydukaanapp.com/</b>
                                                            </a> .</span></p>
                                                        <p><span lang="EN-US">Our Privacy Policy governs your visit to <a href="https://mydukaanapp.com/">
                                                            <b>https://mydukaanapp.com/</b>
                                                        </a>, and explains how we collect, safeguard and disclose information that results from your use of our Service.</span></p>
                                                        <p><span lang="EN-US">We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</span></p>
                                                        <p><span lang="EN-US">Our Terms and Conditions govern all use of our Service and together with the Privacy Policy constitutes your agreement with us.</span></p></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4>2. Definitions</h4><p><strong>SERVICE</strong> means the https://mydukaanapp.com/ website operated by MyDukaanApp</p>
                                                        <p><strong>PERSONAL DATA</strong> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
                                                        <p><strong>USAGE DATA</strong> is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).</p>
                                                        <p><strong>COOKIES</strong> are small files stored on your device (computer or mobile device).</p>
                                                        <p><strong>DATA CONTROLLER</strong> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
                                                        <p><strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</p>
                                                        <p><strong>DATA SUBJECT</strong> is any living individual who is the subject of Personal Data.</p>
                                                        <p><strong>THE USER</strong> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4>3. Information Collection and Use</h4>
                                                        <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4>4. Types of Data Collected</h4>
                                                        <p><strong>Personal Data</strong></p>
                                                        <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (<strong>“Personal Data”</strong>). Personally identifiable information may include, but is not limited to:</p>
                                                        <p>0.1. Email address</p><p>0.2. First name and last name</p>
                                                        <p>0.3. Phone number</p><p>0.4. Address, Country, State, Province, ZIP/Postal code, City</p>
                                                        <p>0.5. Cookies and Usage Data</p>
                                                        <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link</p>
                                                        <p><b><span lang="EN-US">Usage Data</span></b></p>
                                                        <p><span lang="EN-US">We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through any device (<b>“Usage Data”</b>).</span></p>
                                                        <p><span lang="EN-US">This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</span></p><p><span lang="EN-US">When you access Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.</span></p><p><strong>Location Data</strong></p>
                                                        <p>We may use and store information about your location if you give us permission to do so (<strong>“Location Data”</strong>). We use this data to provide features of our Service, to improve and customize our Service.</p>
                                                        <p>You can enable or disable location services when you use our Service at any time by way of your device settings.</p>
                                                        <p><b><span lang="EN-US">Tracking Cookies Data</span></b></p>
                                                        <p><span lang="EN-US">We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</span></p>
                                                        <p><span lang="EN-US">Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.</span></p>
                                                        <p><span lang="EN-US">You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</span></p>
                                                        <p><span lang="EN-US">Examples of Cookies we use:</span></p>
                                                        <p><span lang="EN-US">0.1. <b>Session Cookies:</b> We use Session Cookies to operate our Service.</span></p>
                                                        <p><span lang="EN-US">0.2. <b>Preference Cookies:</b> We use Preference Cookies to remember your preferences and various settings.</span></p>
                                                        <p><span lang="EN-US">0.3. <b>Security Cookies:</b> We use Security Cookies for security purposes.</span></p>
                                                        <p><span lang="EN-US">0.4. <b>Advertising Cookies:</b> Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</span></p>
                                                        <p><b><span lang="EN-US">Tracking Cookies Data</span></b></p>
                                                        <p><span lang="EN-US">We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</span></p>
                                                        <p><span lang="EN-US">Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.</span></p>
                                                        <p><span lang="EN-US">You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</span></p>
                                                        <p><span lang="EN-US">Examples of Cookies we use:</span></p><p><span lang="EN-US">0.1. <b>Session Cookies:</b> We use Session Cookies to operate our Service.</span></p>
                                                        <p><span lang="EN-US">0.2. <b>Preference Cookies:</b> We use Preference Cookies to remember your preferences and various settings.</span></p>
                                                        <p><span lang="EN-US">0.3. <b>Security Cookies:</b> We use Security Cookies for security purposes.</span></p>
                                                        <p><span lang="EN-US">0.4. <b>Advertising Cookies:</b> Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</span></p></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4>5. Use of Data</h4>
                                                        <p><span lang="EN-US">MyDukaanApp uses the collected data for various purposes:</span></p>
                                                        <p><span lang="EN-US">0.1. to provide and maintain our Service;</span></p>
                                                        <p><span lang="EN-US">0.2. to notify you about changes to our Service;</span></p>
                                                        <p><span lang="EN-US">0.3. to allow you to participate in interactive features of our Service when you choose to do so;</span></p>
                                                        <p><span lang="EN-US">0.4. to provide customer support;</span></p>
                                                        <p><span lang="EN-US">0.5. to gather analysis or valuable information so that we can improve our Service;</span></p>
                                                        <p><span lang="EN-US">0.6. to monitor the usage of our Service;</span></p>
                                                        <p><span lang="EN-US">0.7. to detect, prevent and address technical issues;</span></p>
                                                        <p><span lang="EN-US">0.8. to fulfil any other purpose for which you provide it;</span></p>
                                                        <p><span lang="EN-US">0.9. to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</span></p>
                                                        <p><span lang="EN-US">0.10. to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;</span></p>
                                                        <p><span lang="EN-US">0.11. to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</span></p>
                                                        <p><span lang="EN-US">0.12. in any other way we may describe when you provide the information;</span></p>
                                                        <p><span lang="EN-US">0.13. for any other purpose with your consent.</span></p></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4>6. Retention of Data</h4>
                                                        <p><span lang="EN-US">We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</span></p>
                                                        <p><span lang="EN-US">We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</span></p></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4>7. Transfer of Data</h4>
                                                        <p><span lang="EN-US">Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</span></p>
                                                        <p><span lang="EN-US">If you are located outside India, Maharashtra and choose to provide information to us, please note that we transfer the data, including Personal Data, to India, Maharashtra and process it there.</span></p><p><span lang="EN-US">Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</span></p>
                                                        <p><span lang="EN-US">MyDukaanApp will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</span></p></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4>8. Disclosure of Data</h4><p>We may disclose personal information that we collect, or you provide:</p>
                                                        <p>0.1. <strong>Business Transaction.</strong></p>
                                                        <p>If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.</p>
                                                        <p>0.2. <strong>Other cases. We may disclose your information also:</strong></p>
                                                        <p>0.2.1. to our subsidiaries and affiliates;</p>
                                                        <p>0.2.2. to contractors, service providers, and other third parties we use to support our business;</p>
                                                        <p>0.2.3. to fulfill the purpose for which you provide it;</p>
                                                        <p>0.2.4. for the purpose of including your company’s logo on our website;</p>
                                                        <p>0.2.5. for any other purpose disclosed by us when you provide the information;</p>
                                                        <p>0.2.6. with your consent in any other cases;</p>
                                                        <p>0.2.7. if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.</p></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4>9. Security of Data</h4>
                                                        <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4>10. Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h4>
                                                        <p><span lang="EN-US">If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR.</span></p>
                                                        <p><span lang="EN-US">We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</span></p>
                                                        <p><span lang="EN-US">If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at <strong>contact@mydukaanapp.com.</strong></span></p>
                                                        <p><strong><span lang="EN-US">In certain circumstances, you have the following data protection rights:</span></strong></p>
                                                        <p><strong><span lang="EN-US">0.1. the right to access, update or to delete the information we have on you;</span></strong></p>
                                                        <p><strong><span lang="EN-US">0.2. the right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;</span></strong></p>
                                                        <p><strong><span lang="EN-US">0.3. the right to object. You have the right to object to our processing of your Personal Data;</span></strong></p>
                                                        <p><strong><span lang="EN-US">0.4. the right of restriction. You have the right to request that we restrict the processing of your personal information;</span></strong></p>
                                                        <p><strong><span lang="EN-US">0.5. the right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format;</span></strong></p>
                                                        <p><strong><span lang="EN-US">0.6. the right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;</span></strong></p>
                                                        <p><strong><span lang="EN-US">Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not able to provide Service without some necessary data.</span></strong></p>
                                                        <p><strong><span lang="EN-US">You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</span></strong></p></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4><strong>11. Service Providers</strong></h4>
                                                        <p><strong><span lang="EN-US">We may employ third party companies and individuals to facilitate our Service (<b>“Service Providers”</b>), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</span></strong></p>
                                                        <p><strong><span lang="EN-US">These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</span></strong></p></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4><strong>12. Analytics</strong></h4>
                                                        <p><strong>We may use third-party Service Providers to monitor and analyze the use of our Service.</strong></p></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4><strong>13. CI/CD tools</strong></h4><strong>
                                                            We may use third-party Service Providers to automate the development process of our Service.</strong></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4><strong>14. Advertising</strong></h4><strong>
                                                            We may use third-party Service Providers to show advertisements to you to help support and maintain our Service.</strong></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4><strong>15 Behavioral Remarketing</strong></h4><strong>
                                                            We may use remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to our Service.</strong></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4><strong>16. Payments</strong></h4>
                                                        <p><strong>We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors).</strong></p>
                                                        <p><strong>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</strong></p></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4><strong>17. Links to Other Sites</strong></h4>
                                                        <p><strong><span lang="EN-US">Our Services are not intended for use by children under the age of 18 (<b>“Child”</b> or <b>“Children”</b>).</span></strong></p>
                                                        <p><strong><span lang="EN-US">We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</span></strong></p></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4><strong>18. Children’s Privacy</strong></h4>
                                                        <p><strong><span lang="EN-US">Our Services are not intended for use by children under the age of 18 (<b>“Child”</b> or <b>“Children”</b>).</span></strong></p>
                                                        <p><strong><span lang="EN-US">We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</span></strong></p></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4><strong>19. Contact Us</strong></h4><strong>
                                                            If you have any questions about this Privacy Policy, please contact us by email: contact@mydukaanapp.com</strong></div>
                                                    <div class="item-single radius-md mb-30 aos-init aos-animate" data-aos="fade-up">
                                                        <h4><strong>20. Changes to This Privacy Policy</strong></h4>
                                                        <p><strong><span lang="EN-US">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</span></strong></p>
                                                        <p><strong><span lang="EN-US">We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.</span></strong></p>
                                                        <p><strong><span lang="EN-US">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</span></strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </>
    );
};
export default PrivacyPolicy;